
        @import "@/styles/griddle-overrides.scss";
        @import "@braid/griddle/scss/griddle.scss";
        




































































































































































































@import '../styles/_colors.scss';

.login {
  display: flex;
  flex-direction: column;
  width: span(12);
  padding: 2rem;
  background: white;
  border-radius: 3px;
  box-shadow: 0 2px 4px rgba($color-grey-400, 0.3);

  @media screen and (min-width: bp(m)) {
    width: span(4);
  }

  @media screen and (min-width: bp(s)) {
    width: span(6);
  }
}

.login__form {
  padding-bottom: 2rem;
  border-bottom: 1px solid $color-grey-100;
}

.login__footer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem 0.5rem;
  margin-top: 2rem;
}

.login__secondary {
  display: flex;
  flex-wrap: wrap;
  margin: 1.75rem -0.25rem -0.25rem;

  & > .btn {
    margin: 0.25rem;
  }
}

.error {
  margin: 1.5rem 0;
  padding: 1em 1.5em;
  color: black;
  background: rgba($color-red, 0.25);
  border: 1px solid $color-red;
  border-radius: 2px;
}
